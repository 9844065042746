import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import SmoothScroll from "smooth-scroll";

export default function Header({ data, inPage, errorPage = false }) {
    const { menu, logo, currentLang, languages, online } = data;
    const currentUrl = new URL(window.location.href);
    useEffect(() => {
        let position = window.pageYOffset;
        const headerEl = document.querySelector("header");
        window.onscroll = function () {
            let scroll = window.pageYOffset;
            if (scroll > position) headerEl.classList.add("fixed");
            position = scroll;
            if (position === 0) headerEl.classList.remove("fixed");
        };
        new SmoothScroll('a[href*="#"]', {
            speed: 400,
            durationMax: 1000,
            durationMin: 400,
            speedAsDuration: false,
            header: "header",
        });
    }, []);

    const menuHandler = () => {
        const drawer = document.querySelector(".drawer");
        drawer.classList.add("active");
        let backdrop = document.createElement("div");
        backdrop.classList.add("backdrop");
        backdrop.addEventListener("click", function (el) {
            const drawer = document.querySelector(".drawer");
            drawer.classList.remove("active");
            el.target.classList.add("removing");
            let element = el.currentTarget;
            setTimeout(() => {
                element.remove();
            }, 500);
        });
        document.body.appendChild(backdrop);
    };

    return (
        <>
            <div className="sticky-right">
                <ul className="d-flex flex-column gap-2">
                    <li className="d-flex align-items-center gap-2">
                        <svg
                            id="Outline"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            viewBox="0 0 48 48"
                            width={"1.8em"}
                            height={"1.8em"}
                        >
                            <path
                                d="M40,5H12c-1.6543,0-3,1.3457-3,3v19h-1c-1.6543,0-3,1.3457-3,3v6c0,1.6543,1.3457,3,3,3h1.21875l.81152,3.24268c.09277.37402.39355.66113.77154.73732.37381.07694.76916-.07479.99799-.37989l2.7002-3.6001h9.5c1.6543,0,3-1.3457,3-3v-5h13c1.6543,0,3-1.3457,3-3V8c0-1.6543-1.3457-3-3-3ZM24,27h-9.58594l7.58594-7.58594,3.29297,3.29297c.19531.19531.45117.29297.70703.29297s.51172-.09766.70703-.29297l3.29297-3.29297,9.58594,9.58594h-12.7702c-.41412-1.1615-1.51379-2-2.81573-2ZM26,20.58594L12.41406,7h27.17188l-13.58594,13.58594ZM31.41406,18l9.58594-9.58594v19.17188l-9.58594-9.58594ZM11,8.41406l9.58594,9.58594-9,9h-.58594V8.41406ZM25,36c0,.55127-.44824,1-1,1h-10c-.31445,0-.61133.14795-.7998.3999l-1.73926,2.31934-.49121-1.96191c-.11133-.44482-.51074-.75732-.96973-.75732h-2c-.55176,0-1-.44873-1-1v-6c0-.55127.44824-1,1-1h16c.55176,0,1,.44873,1,1v6Z"
                                style={{
                                    fill: "#fff",
                                    strokeWidth: 0,
                                }}
                            />
                            <circle
                                cx="10"
                                cy="33"
                                r="1"
                                style={{
                                    fill: "#fff",
                                    strokeWidth: 0,
                                }}
                            />
                            <circle
                                cx="14"
                                cy="33"
                                r="1"
                                style={{
                                    fill: "#fff",
                                    strokeWidth: 0,
                                }}
                            />
                            <circle
                                cx="18"
                                cy="33"
                                r="1"
                                style={{
                                    fill: "#fff",
                                    strokeWidth: 0,
                                }}
                            />
                            <circle
                                cx="22"
                                cy="33"
                                r="1"
                                style={{
                                    fill: "#fff",
                                    strokeWidth: 0,
                                }}
                            />
                        </svg>
                        <a href={"#" + online.category_language.slug}>
                            <span>{translations.iletisim_formunu_doldur}</span>
                        </a>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                        <svg
                            id="Layer_2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 52.79 49.02"
                            width={"1.5em"}
                            height={"1.5em"}
                        >
                            <g id="Layer_1-2" data-name="Layer_1">
                                <g>
                                    <path
                                        d="M46.5,27V9.7c0-2.5-2-4.5-4.5-4.5H5.5c-2.5,0-4.5,2-4.5,4.5v29.7c0,2.5,2,4.5,4.5,4.5h26.1"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <line
                                        x1="1"
                                        y1="12.9"
                                        x2="46.5"
                                        y2="12.9"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <path
                                        d="M12.3,9.2c-2,0-3.6-1.8-3.6-4.1s1.6-4.1,3.6-4.1,3.6,1.8,3.6,4.1"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <path
                                        d="M23.8,9.2c-2,0-3.6-1.8-3.6-4.1s1.6-4.1,3.6-4.1,3.6,1.8,3.6,4.1"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <path
                                        d="M35.2,9.2c-2,0-3.6-1.8-3.6-4.1s1.6-4.1,3.6-4.1,3.6,1.8,3.6,4.1"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <rect
                                        x="8"
                                        y="18.7"
                                        width="7.3"
                                        height="7.3"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <rect
                                        x="20.4"
                                        y="18.7"
                                        width="7.3"
                                        height="7.3"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <rect
                                        x="8"
                                        y="30.5"
                                        width="7.3"
                                        height="7.3"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <rect
                                        x="20.4"
                                        y="30.5"
                                        width="7.3"
                                        height="7.3"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <rect
                                        x="32.8"
                                        y="18.7"
                                        width="7.3"
                                        height="7.3"
                                        style={{
                                            fill: "none",
                                            stroke: "#fff",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2px",
                                        }}
                                    />
                                    <g>
                                        <path
                                            d="M29.97,36.3c0,.26-.05.52-.05.78,0,6.12,4.96,11.03,11.01,11.03s11.06-4.91,11.06-11.03-4.96-11.03-11.01-11.03"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: "1.6px",
                                            }}
                                        />
                                        <path
                                            d="M40.93,26.06c1.88,0,3.44,2.88,4.12,6.95.21,1.25.31,2.67.31,4.13s-.1,2.82-.31,4.13c-.63,4.08-2.24,6.95-4.12,6.95s-3.44-2.88-4.12-6.95c-.21-1.25-.31-2.67-.31-4.13v-.78"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: "1.6px",
                                            }}
                                        />
                                        <path
                                            d="M40.93,32.7c1.46,0,2.82.1,4.12.31,4.07.63,6.94,2.25,6.94,4.08s-2.87,3.45-6.94,4.13c-1.25.21-2.66.31-4.12.31s-2.82-.1-4.12-.31c-4.07-.63-6.94-2.25-6.94-4.13,0-.26.05-.52.21-.78"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: "1.6px",
                                            }}
                                        />
                                        <path
                                            d="M40.14,48.14c.26,0,.52.05.78.05,6.11,0,11.01-4.97,11.01-11.03s-4.91-11.08-11.01-11.08-11.01,4.97-11.01,11.03"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: "1.6px",
                                            }}
                                        />
                                        <path
                                            d="M40.93,48.22c-1.88,0-3.44-2.88-4.12-6.95-.21-1.25-.31-2.67-.31-4.13s.1-2.82.31-4.13c.63-4.08,2.24-6.95,4.12-6.95s3.44,2.88,4.12,6.95c.21,1.25.31,2.67.31,4.13v.78"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: "1.6px",
                                            }}
                                        />
                                        <path
                                            d="M40.93,41.56c-1.46,0-2.82-.1-4.12-.31-4.07-.63-6.94-2.25-6.94-4.08s2.87-3.45,6.94-4.13c1.25-.21,2.66-.31,4.12-.31s2.82.1,4.12.31c4.07.63,6.94,2.25,6.94,4.13,0,.26-.05.52-.21.78"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: "1.6px",
                                            }}
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <a href={online.category_language.taxonomy}>
                            <span>{online.category_language.name}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="drawer">
                <ul>
                    {menu.map((item, index) => {
                        if (item.id == 249 || item.id == 256) {
                            return;
                        }
                        return (
                            <li key={index}>
                                <a
                                    data-scroll
                                    href={
                                        item.category_language?.slug
                                            ? inPage
                                                ? item.category_language
                                                      .taxonomy
                                                : "#" +
                                                  item.category_language.slug
                                            : item.category_language.taxonomy
                                    }
                                >
                                    {item.category_language.name}
                                </a>
                            </li>
                        );
                    })}
                    <li className="misc">
                        <div className="btn-search">
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        {currentLang === "tr" ? (
                            languages.includes("en") && (
                                <a href="/en" className="lang">
                                    <img
                                        alt=""
                                        src="/assets/img/uk-flag.png"
                                        width="27px"
                                    />
                                    <span>EN</span>
                                </a>
                            )
                        ) : (
                            <a href="/" className="lang">
                                <img
                                    alt=""
                                    src="/assets/img/tr-flag.png"
                                    width="27px"
                                />
                                <span>TR</span>
                            </a>
                        )}
                    </li>
                </ul>
            </div>
            <header
                className={inPage && "in-page"}
                style={errorPage ? { background: "transparent" } : {}}
            >
                <div className="container">
                    <a
                        href={currentLang === "tr" ? "/" : "/" + currentLang}
                        className="logo"
                    >
                        <img alt="" src={"/uploads/" + logo} />
                    </a>
                    <div className="menu-toggler" onClick={menuHandler}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <ul className="menu">
                        {menu.map((item, index) => {
                            if (item.id == 249 || item.id == 256) {
                                return;
                            }
                            return (
                                <li key={index}>
                                    {" "}
                                    <a
                                        data-scroll
                                        className={
                                            item.category_language.taxonomy ===
                                                currentUrl.pathname &&
                                            item.category_language.taxonomy !==
                                                "/"
                                                ? "active"
                                                : ""
                                        }
                                        href={
                                            item.category_language?.slug
                                                ? inPage
                                                    ? item.category_language
                                                          .taxonomy
                                                    : "#" +
                                                      item.category_language
                                                          .slug
                                                : item.category_language
                                                      .taxonomy
                                        }
                                    >
                                        {item.category_language.name}
                                    </a>
                                </li>
                            );
                        })}
                        <li className="misc">
                            <div className="btn-search">
                                <FontAwesomeIcon icon={faSearch} />
                            </div>
                            {currentLang === "tr" ? (
                                languages.includes("en") && (
                                    <a href="/en" className="lang">
                                        <img
                                            alt=""
                                            src="/assets/img/uk-flag.png"
                                            className="me-1"
                                            width="27px"
                                        />
                                        <span>EN</span>
                                    </a>
                                )
                            ) : (
                                <a href="/" className="lang">
                                    <img
                                        alt=""
                                        src="/assets/img/tr-flag.png"
                                        className="me-1"
                                        width="27px"
                                    />
                                    <span>TR</span>
                                </a>
                            )}
                        </li>
                    </ul>
                </div>
            </header>
        </>
    );
}
