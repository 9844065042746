import React,{ useContext } from 'react'
import { motion } from "framer-motion";
import axios from 'axios';
import useSWR from 'swr';
import ActivityIndicator from './ActivityIndicator';
import useScreenSize from '../extras/screenSize';
import AppContext from '../context';
import LandingForm from './LandingForm';

export default function Products(props) {
    const compURL = `/api/components/${currentLang}/products`;
    const fetcher = url => axios.get(url).then(res => res.data)
    const { data, error } = useSWR(compURL, fetcher)
    const { width:screenWidth } = useScreenSize();
    const {setContextData} = useContext(AppContext);

    const Btn = () => {
        switch (props?.page) {
            case 'landing':
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, x:'100'},
                    visible: { opacity: 1, x:0 }
                }}
                transition={{ duration: 1, delay: 2 }}
                onClick={() => setContextData({
                    status: 'visible',
                    content: <LandingForm image={props?.modalImage} />
                })}
                style={{ maxWidth:'300px' }}
                className="mt-3 btn btn-bwa btn-orange">
                    {translations.landing_btn}
                </motion.a>

            default:
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, x:'100'},
                    visible: { opacity: 1, x:0 }
                }}
                transition={{ duration: 1, delay: 2 }}
                href={data?.category_language.taxonomy} className="mt-3 btn btn-bwa">
                    {translations.seeall}
                </motion.a>
        }
    }
  return (
    <ActivityIndicator waitFor={data}>
        <section className="product" id={data?.category_language.slug}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="mt-4 row mt-xl-0">
                            {data?.sub_items.map((item,index) => {
                                return <div className="mb-4 col-xl-6" key={index}>
                                <motion.a
                                initial={{ top:0 }}
                                whileInView={{ top: screenWidth > 1024 ? (index % 2 === 1 ? -40 : 0) : 0 }}
                                transition={{ duration:.6 }}
                                target="_blank"
                                href={item.category_language.taxonomy}
                                className="item text-decoration-none text-reset" style={{ "--color": item.category_language.extra.color }}>
                                    <div className="icon"> <img alt={item.category_language.alt?.file} src={item.file} /> </div>
                                    <h2>{item.category_language.name}</h2>
                                    <p>{item.category_language.description}</p>
                                </motion.a>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="col-xl-6 pe-xl-5">
                        <motion.small
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, x:'100'},
                            visible: { opacity: 1, x:0 }
                        }}
                        transition={{ duration: .7, delay: 0 }}>{data?.category_language.name}</motion.small>
                        <motion.h1
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, x:'100'},
                            visible: { opacity: 1, x:0 }
                        }}
                        transition={{ duration: 1.2, delay: 1 }}>{data?.category_language.description}</motion.h1>
                        <motion.h6
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, x:'100'},
                            visible: { opacity: 1, x:0 }
                        }}
                        transition={{ duration: 1.2, delay: 1.5 }}
                        dangerouslySetInnerHTML={{ __html: data?.category_language.extra.text1 }}></motion.h6>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, x:'100'},
                            visible: { opacity: 1, x:0 }
                        }}
                        transition={{ duration: 1, delay: 1.8 }}
                        dangerouslySetInnerHTML={{ __html: data?.category_language.extra.homeSummary }}></motion.div>
                        <Btn />
                    </div>
                </div>
            </div>
        </section>
    </ActivityIndicator>
  )
}
