import React,{ useContext } from 'react'
import { motion } from "framer-motion";
import Spinner from './Spinner';
import axios from 'axios';
import useSWR from 'swr';
import useScreenSize from '../extras/screenSize';
import AppContext from '../context';
import LandingForm from './LandingForm';

export default function Services(props) {
    const compURL = `/api/components/${currentLang}/services`;
    const fetcher = url => axios.get(url).then(res => res.data)
    const { data, error } = useSWR(compURL, fetcher)
    const {setContextData} = useContext(AppContext);
    const { width: screenWidth } = useScreenSize();

    const Btn = () => {
        switch (props?.page) {
            case 'landing':
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, y:'50'},
                    visible: { opacity: 1, y:0 }
                }}
                transition={{ duration: .5, delay: 0 }}
                onClick={() => setContextData({
                    status: 'visible',
                    content: <LandingForm image={props?.modalImage} />
                })}
                style={{ maxWidth:'300px' }}
                className="mx-auto btn btn-bwa btn-orange">
                    {translations.landing_btn}
                </motion.a>

            default:
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, y:'50'},
                    visible: { opacity: 1, y:0 }
                }}
                transition={{ duration: .5, delay: 0 }}
                href={data.category_language.taxonomy}
                className="mx-auto btn btn-bwa">
                    {translations.seeall}
                </motion.a>
        }
    }

    return (
        data ?
        <section className="services" id={data.category_language.slug}>
            <div className="container ">
                <div className="row">
                    <div className="col-xl-6 pe-3 pe-xl-5">
                        <motion.small
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, x:'-100'},
                            visible: { opacity: 1, x:0 }
                        }}
                        transition={{ duration: 1.2 }}
                        className="d-block"
                        >
                            {data.category_language.name}</motion.small>
                        <motion.h1
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, x:'-100'},
                            visible: { opacity: 1, x:0 }
                        }}
                        transition={{ duration: 1.2, delay:.9 }}>
                            {data.category_language.description}
                        </motion.h1>
                        <motion.h6
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, x:'-100'},
                            visible: { opacity: 1, x:0 }
                        }}
                        transition={{ duration: 1.2, delay: 1.5 }}
                        dangerouslySetInnerHTML={{ __html: data.category_language.contents }}></motion.h6>
                    </div>
                    {data.sub_items.map((item,index) => {
                        return <div className="mb-4 col-xl-3" key={index}>
                        <motion.a
                        initial={{ top:0 }}
                        whileHover={{ scale:1.1 }}
                        whileInView={{ top: screenWidth > 1024 ? (index % 2 === 0 ? -40 : 0) : 0 }}
                        viewport={{ once:true }}
                        transition={{ duration:.6 }}
                        href={item.category_language.taxonomy}
                        className="item text-decoration-none text-reset"
                        style={{ "--color": item.category_language.extra.color }}>
                            <div className="icon">
                            <img src={item.file} alt={item.category_language.alt?.file} />
                            </div>
                            <h2>{item.category_language.name}</h2>
                            <p>{item.category_language.description}</p>
                        </motion.a>
                    </div>
                    })}

                </div>
                <div className="my-3 w-100">
                    <Btn />
                </div>
            </div>
        </section> : <Spinner />
  )
}
