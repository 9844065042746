import React from 'react'
import { motion } from 'framer-motion'

export default function ContactInfo({data}) {
  return (
    <>
    <motion.div
    initial="hidden"
    whileInView="visible"
    viewport={{ once:true }}
    variants={{
        hidden: { opacity:0, x:'100'},
        visible: { opacity: 1, x:0 }
    }}
    transition={{ duration: .6, delay: 0 }}
    className="contact-card">
        <div className="icon">
            <img alt=""src="/assets/img/icon-tel.png" width="37" />
        </div>
        <div className="content">
            <h6>{translations.phone}</h6>
            <a href={'tel:'+data.category_language.extra.tel}>{data.category_language.extra.tel}</a>
        </div>
    </motion.div>
    <motion.div
    initial="hidden"
    whileInView="visible"
    viewport={{ once:true }}
    variants={{
        hidden: { opacity:0, x:'100'},
        visible: { opacity: 1, x:0 }
    }}
    transition={{ duration: .6, delay: .5 }}
    className="contact-card">
        <div className="icon">
            <img alt=""src="/assets/img/icon-map.png" width="37" />
        </div>
        <div className="content">
            <h6>{translations.address}</h6>
            <p>
                {data.category_language.extra.adres}
            </p>
        </div>
    </motion.div>
    <motion.div
    initial="hidden"
    whileInView="visible"
    viewport={{ once:true }}
    variants={{
        hidden: { opacity:0, x:'100'},
        visible: { opacity: 1, x:0 }
    }}
    transition={{ duration: .6, delay: 1 }}
    className="contact-card">
        <div className="icon">
            <img alt=""src="/assets/img/icon-message.png" width="37" />
        </div>
        <div className="content">
            <h6>{translations.email_address}</h6>
            <a href={'mailto:'+data.category_language.extra.email}>{data.category_language.extra.email}</a>
        </div>
    </motion.div>
    </>
  )
}
