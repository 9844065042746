import React, { useRef, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { IMaskInput } from "react-imask";
import Axios from "axios";
import CountryCodes from "./../json/CountryCodes.json";
import AppContext from "./../context";

export default function ContactForm({ thankPage }) {
    const ref = useRef(null);
    const { setContextData } = useContext(AppContext);

    useEffect(() => {
        // reCaptcha V3
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js?render=6Le6L8MfAAAAAKZ497vNZxZWTWeq8ciGBdRrVp1Z";
        document.body.appendChild(script);
    }, []);

    const Spinner = () => (
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );
    const handleForm = (e) => {
        e.preventDefault();
        const formdata = new FormData(e.target);
        window.grecaptcha.ready((_) => {
            window.grecaptcha
                .execute("6Le6L8MfAAAAAKZ497vNZxZWTWeq8ciGBdRrVp1Z", {
                    action: "submit",
                })
                .then((token) => {
                    formdata.append("recaptcha_token", token);
                    setContextData({
                        status: "visible",
                        content: <Spinner />,
                    });

                    Axios.post(`/misc/form/${currentLang}/contact`, formdata)
                        .then((e) => {
                            switch (currentLang) {
                                case "en":
                                    window.location.href = "/en/thanks-page";
                                    break;

                                default:
                                    window.location.href = "/tesekkur-sayfasi";
                                    break;
                            }
                            // if (thankPage) {
                            //     switch (currentLang) {
                            //         case "en":
                            //             window.location.href =
                            //                 "/en/thanks-page";
                            //             break;

                            //         default:
                            //             window.location.href =
                            //                 "/tesekkur-sayfasi";
                            //             break;
                            //     }
                            // } else {
                            //     setContextData({
                            //         status: "visible",
                            //         content: translations.form_successful,
                            //     });
                            // }
                        })
                        .catch(({ response }) => {
                            setContextData({
                                status: "visible",
                                content: translations.form_error,
                            });
                            console.log(response.data.errors);
                        });
                });
        });
    };

    const handleInputChange = (value, mask) => {
        const unmaskedValue = mask.unmaskedValue; // Maskesiz değeri alıyoruz

        console.log(unmaskedValue);

        // İlk karakter sıfır ise değeri temizle
        if (unmaskedValue.length === 1 && unmaskedValue === "0") {
            mask.value = ""; // Maskeli girişi temizle
            return;
        }

        const email = document.getElementById("email");
        if (value.length === 15) {
            // Maskeli değer 15 karakter uzunluğunda olmalı
            email.removeAttribute("required");
        } else {
            email.setAttribute("required", "required");
        }
    };

    return (
        <motion.form
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
                hidden: { opacity: 0, x: "100" },
                visible: { opacity: 1, x: 0 },
            }}
            transition={{ duration: 0.6, delay: 1 }}
            onSubmit={handleForm}
            className="row h-100"
        >
            <div className="col-xl-6">
                <input
                    type="text"
                    required
                    name="name"
                    placeholder={translations.contactform_name}
                    className="form-control"
                />
            </div>
            <div className="col-xl-6 d-flex align-items-center">
                <select
                    name="dial_code"
                    required
                    className="form-select rounded-0 rounded-start"
                    defaultValue={"+90"}
                    style={{ width: "40%", height: "60px" }}
                >
                    {CountryCodes.map((item, index) => {
                        return (
                            <option value={item.dial_code} key={index}>
                                {item.dial_code} - {item.name}
                            </option>
                        );
                    })}
                </select>
                <IMaskInput
                    mask={"(000) 000 00 00"}
                    unmask={true}
                    ref={ref}
                    name="phone"
                    id="phone"
                    onAccept={(value, mask) => handleInputChange(value, mask)}
                    // onChange={(e, maskRef) => {
                    //     const unmaskedValue = maskRef.unmaskedValue;
                    //     console.log(unmaskedValue);

                    //     // İlk karakter sıfır ise değeri temizle
                    //     if (
                    //         unmaskedValue.length === 1 &&
                    //         unmaskedValue === "0"
                    //     ) {
                    //         maskRef.value = ""; // Maskeli girişi temizle
                    //     }

                    //     const email = document.getElementById("email");
                    //     if (e.target.value.length === 15) {
                    //         email.removeAttribute("required");
                    //     } else {
                    //         email.setAttribute("required", "required");
                    //     }
                    // }}
                    placeholder={translations.contactform_phone}
                    style={{ width: "60%" }}
                    required
                    className="form-control rounded-0 rounded-end"
                />
            </div>
            <div className="col-xl-6">
                <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={(e) => {
                        const phone = document.getElementById("phone");
                        if (e.target.value.length > 0) {
                            phone.removeAttribute("required");
                        } else {
                            phone.setAttribute("required", "required");
                        }
                    }}
                    required
                    placeholder={translations.contactform_email}
                    className="form-control"
                />
            </div>
            <div className="col-xl-6">
                <input
                    type="text"
                    name="company"
                    placeholder={translations.contactform_company}
                    className="form-control"
                />
            </div>
            <div className="col-12">
                <input
                    type="text"
                    name="subject"
                    required
                    placeholder={translations.contactform_subject}
                    className="form-control"
                />
            </div>
            <div className="col-12">
                <textarea
                    name="message"
                    required
                    placeholder={translations.contactform_message}
                    className="form-control"
                ></textarea>
            </div>
            <div className="col-12">
                <small dangerouslySetInnerHTML={{__html: translations.contactform_kvkk}}></small>
            </div>
            <div className="col-xl-6 text-start">
                <div
                    className="g-recaptcha"
                    data-sitekey="_reCAPTCHA_site_key_"
                    data-size="invisible"
                ></div>
            </div>
            <div className="col-xl-6 text-end">
                <button type="submit" className="btn btn-bwa ms-auto">
                    {translations.send}
                </button>
            </div>
        </motion.form>
    );
}
