import React from 'react'
import ContactForm from './ContactForm'

export default function LandingForm({ image }) {
  return (
    <div className='mx-0 row w-100'>
        <div className="py-4 pe-4 col-xl-8">
            <ContactForm thankPage={true} />
        </div>
        <div
        style={{ backgroundImage: `url('${image}')` }}
        className="col-xl-4 modal-img"></div>
    </div>
  )
}
