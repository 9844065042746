import React,{ useContext } from 'react'
import { motion } from "framer-motion";
import Image from './Image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import useSWR from 'swr';
import Spinner from './Spinner';
import AppContext from '../context';
import LandingForm from './LandingForm';

export default function Blog(props) {
    const compURL = `/api/components/${currentLang}/blog`;
    const fetcher = url => axios.get(url).then(res => res.data)
    const { data, error } = useSWR(compURL, fetcher)
    const {setContextData} = useContext(AppContext);

    const Btn = () => {
        switch (props?.page) {
            case 'landing':
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, y:'50'},
                    visible: { opacity: 1, y:0 }
                }}
                transition={{ duration: .5, delay: .3}}
                style={{ maxWidth:'300px' }}
                onClick={() => setContextData({
                    status: 'visible',
                    content: <LandingForm image={props?.modalImage} />
                })}
                className="mx-auto my-5 btn btn-bwa btn-orange">
                {translations.landing_btn}
                </motion.a>

            default:
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, y:'50'},
                    visible: { opacity: 1, y:0 }
                }}
                transition={{ duration: .5, delay: .3}}
                href={data?.category_language.taxonomy}
                className="mx-auto my-5 btn btn-bwa">
                {translations.all_articles}
                </motion.a>
        }
    }

    return (
        data ?
        <section className="blog" id={data?.category_language.slug}>
            <div className="container">
                <motion.small
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, y:'50'},
                    visible: { opacity: 1, y:0, display:'block' }
                }}
                transition={{ duration: .3, delay: 0 }}>{data?.category_language.name}</motion.small>
                <motion.h1
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, y:'50'},
                    visible: { opacity: 1, y:0 }
                }}
                transition={{ duration: .3, delay: 1 }}>{data?.category_language.description}</motion.h1>
                <div className="row">
                    {data?.sub_items.map((item,index) => {
                        return <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { opacity:0, y:'50'},
                            visible: { opacity: 1, y:0 }
                        }}
                        transition={{ duration: 1.2, delay: (index * .5) }}
                        className="mb-3 col-xl-4 mb-xl-0" key={index}>
                        <div className="item">
                            <div className="img-container">
                                <div className="time">
                                    <FontAwesomeIcon icon={faCalendarAlt} /> {item.category_language.extra?.date}
                                </div>
                                <Image image={item.file} alt={item.category_language.alt?.file} />
                            </div>
                            <div className="p-4">
                                <h2>{item.category_language.name}</h2>
                                <p>
                                    {item.category_language.description}
                                </p>
                                <a href={item.category_language.taxonomy} className="read-more">
                                    {translations.readmore} &nbsp;
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </a>
                            </div>
                        </div>
                    </motion.div>
                    })}
                </div>
                <Btn />
            </div>
        </section> : <Spinner />
    )
}
