import React,{useEffect, useContext} from 'react'
import Swiper,{Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { motion } from 'framer-motion';
import Image from './Image';
import AppContext from '../context'
import VisibilitySensor from "react-visibility-sensor";
import CountUp from 'react-countup';
import axios from 'axios';
import useSWR from 'swr';
import Spinner from './Spinner';

export default function Feedback(props) {
    const compURL = `/api/components/${currentLang}/feedbacks`;
    const fetcher = url => axios.get(url).then(res => res.data)
    const { data, error } = useSWR(compURL, fetcher)

    const {setContextData} = useContext(AppContext);
    useEffect(() => {
      //feedbacks slider
      Swiper.use([Pagination])
      new Swiper('#feedback-slider', {
        pagination: {
            el: ".swiper-pagination",
            }
    });
    }, [])

  return (
    data ?
        <section className="feedback" id={data?.category_language.slug}>
            <div className="container">
                <small>{data?.category_language.name}</small>
                <h1>{data?.category_language.description}</h1>
            </div>
            <div className="fb-sec-1">
                <div className="container">
                    <div className="swiper" id="feedback-slider">
                        <div className="swiper-wrapper">
                            {data?.sub_items.map((item,index) => {
                                return <div className="swiper-slide" key={index}>
                                <div className="slide-item">
                                    <div
                                    onClick={() => setContextData({
                                        status: 'visible',
                                        video: item.category_language.extra.video_id
                                    })}
                                    className="play-button">
                                        <img alt=""src="/assets/img/Play.svg" />
                                    </div>
                                    <Image image={item.file} alt={item.category_language.alt?.file} />
                                </div>
                            </div>
                            })}
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </div>
            <div className="fb-sec-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-3">
                            <div className="stat-item">
                                <div className="num">
                                    <CountUp
                                    start={0}
                                    end={parseInt(data?.category_language.extra.stat1.split(",")[0])}
                                    duration={4}
                                    delay={0}
                                    useEasing={true}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                    +
                                </div>
                                <p>{data?.category_language.extra.stat1.split(",")[1]}</p>
                                <div className="progress-bar">
                                    <motion.div
                                    initial={{ width:0, }}
                                    whileInView={{ width: data?.category_language.extra.stat1.split(",")[2]+'%' }}
                                    transition={{ duration: 2 }}
                                    className="progress"></motion.div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3">
                            <div className="stat-item">
                                <div className="num">
                                    <CountUp
                                    start={0}
                                    end={parseInt(data?.category_language.extra.stat2.split(",")[0])}
                                    duration={4}
                                    delay={0}
                                    useEasing={true}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                    +
                                </div>
                                <p>{data?.category_language.extra.stat2.split(",")[1]}</p>
                                <div className="progress-bar">
                                    <motion.div
                                    initial={{ width:0, }}
                                    whileInView={{ width: data?.category_language.extra.stat2.split(",")[2]+'%' }}
                                    transition={{ duration: 2 }}
                                    className="progress"></motion.div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3">
                            <div className="stat-item">
                                <div className="num">
                                    <CountUp
                                    start={0}
                                    end={parseInt(data?.category_language.extra.stat3.split(",")[0])}
                                    duration={4}
                                    delay={0}
                                    useEasing={true}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                    +
                                </div>
                                <p>{data?.category_language.extra.stat3.split(",")[1]}</p>
                                <div className="progress-bar">
                                    <motion.div
                                    initial={{ width:0, }}
                                    whileInView={{ width: data?.category_language.extra.stat3.split(",")[2]+'%' }}
                                    transition={{ duration: 2 }}
                                    className="progress"></motion.div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3">
                            <div className="stat-item">
                                <div className="num">{data?.category_language.extra.stat4.split(",")[0]}</div>
                                <p>{data?.category_language.extra.stat4.split(",")[1]}</p>
                                <div className="progress-bar">
                                    <motion.div
                                    initial={{ width:0, }}
                                    whileInView={{ width: data?.category_language.extra.stat4.split(",")[2]+'%' }}
                                    transition={{ duration: 2 }}
                                    className="progress"></motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> : <Spinner />
  )
}
