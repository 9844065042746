import React, { useEffect, setState, useState } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import axios from "axios";
import useSWR from "swr";

export default function Awards({ appendClass }) {
    const compURL = `/api/components/${currentLang}/awards`;
    const fetcher = (url) => axios.get(url).then((res) => res.data);
    const { data, error } = useSWR(compURL, fetcher);

    const [current, setCurrent] = useState(1);
    const [pause, setPause] = useState(false);

    useEffect(() => {
        console.log(data);
    }, []);

    let timeout;
    useEffect(() => {
        timeout = startTime();
        return () => {
            clearTimeout(timeout);
        };
    }, [current]);

    useEffect(() => {
        if (pause) {
            clearTime();
        } else {
            startTime();
        }
    }, [pause]);

    const startTime = () => {
        if (pause) return false;
        return setTimeout(() => {
            if (data?.sub_items.length <= current) {
                setCurrent(1);
            } else {
                setCurrent(current + 1);
            }
        }, 3000);
    };

    const clearTime = () => {
        clearTimeout(timeout);
    };

    return (
        <section className="references my-5">
            <motion.small
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={{
                    hidden: { opacity: 0, y: "50" },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.4, delay: 0 }}
            >
                {data?.category_language.description}
            </motion.small>
            <motion.h1
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={{
                    hidden: { opacity: 0, y: "50" },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.4, delay: 0.4 }}
            >
                {data?.category_language.name}
            </motion.h1>
            <div className="mobile">
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {data?.sub_items &&
                        data?.sub_items.map((value, key) => {
                            return (
                                <>
                                    {value.file2 !== null ? (
                                        <>
                                            <SwiperSlide
                                                key={key}
                                                className="text-center"
                                            >
                                                <img
                                                    alt=""
                                                    className="img-fluid"
                                                    src={value.file}
                                                />
                                            </SwiperSlide>
                                            <SwiperSlide
                                                key={key}
                                                className="text-center"
                                            >
                                                <img
                                                    alt=""
                                                    className="img-fluid"
                                                    src={value.file2}
                                                />
                                            </SwiperSlide>
                                        </>
                                    ) : (
                                        <SwiperSlide
                                            key={key}
                                            className="text-center"
                                        >
                                            <img
                                                alt=""
                                                className="img-fluid"
                                                src={value.file}
                                            />
                                        </SwiperSlide>
                                    )}
                                </>
                            );
                        })}
                </Swiper>
            </div>
            <div
                className="desktop"
                style={{
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                }}
            >
                <div
                    className="container"
                    onMouseEnter={() => {
                        setPause(true);
                    }}
                    onMouseLeave={() => {
                        setPause(false);
                    }}
                >
                    <div className="awards">
                        {data?.sub_items &&
                            data?.sub_items.map((value, key) => {
                                return (
                                    <div
                                        style={{
                                            width: "100%",
                                            flexGrow:
                                                key + 1 == current ? "3" : "",
                                        }}
                                        className={"flex-slide"}
                                        key={key}
                                        onMouseEnter={() => {
                                            setPause(true);
                                            setCurrent(key + 1);
                                        }}
                                        onMouseLeave={() => {
                                            setPause(false);
                                            setCurrent(key + 1);
                                        }}
                                    >
                                        <div
                                            className="slide-item"
                                            onClick={() => {
                                                if (
                                                    value.category_language
                                                        .taxonomy != null
                                                ) {
                                                    window.location.href =
                                                        value.category_language.taxonomy;
                                                }
                                            }}
                                        >
                                            <div className="slide-item-zero">
                                                <div
                                                    className={
                                                        "d-flex align-items-center justify-content-center"
                                                    }
                                                >
                                                    <img
                                                        alt=""
                                                        className="slide-item-image"
                                                        style={
                                                            value.file2 != null
                                                                ? {
                                                                      width: "178px",
                                                                      objectFit:
                                                                          "cover",
                                                                  }
                                                                : {}
                                                        }
                                                        src={value.file}
                                                    />
                                                    {value.file2 != null && (
                                                        <img
                                                            alt=""
                                                            className="slide-item-image"
                                                            style={
                                                                key + 1 !=
                                                                current
                                                                    ? {
                                                                          display:
                                                                              "none",
                                                                      }
                                                                    : {
                                                                          width: "178px",
                                                                          objectFit:
                                                                              "cover",
                                                                      }
                                                            }
                                                            src={value.file2}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {key + 1 == current && (
                                                <div className="slide-item-two px-3">
                                                    <h4
                                                        style={{
                                                            fontWeight: "bold",
                                                            width: "330px",
                                                        }}
                                                    >
                                                        {
                                                            value
                                                                .category_language
                                                                .name
                                                        }
                                                    </h4>
                                                    <p className="mb-0 pb-0">
                                                        {
                                                            value
                                                                .category_language
                                                                .description
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </section>
    );
}
