import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import SwipeableViews from "react-swipeable-views";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Image from "./Image";
import Spinner from "./Spinner";
import AppContext from "../context";
import LandingForm from "./LandingForm";

const url = `/api/components/${currentLang}/portfolio`;
//const fetcher = url => axios.get(url).then(res => res.data)

export default function Portfolio(props) {
    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [portfolioIndex, setPortfolioIndex] = useState(0);
    const { setContextData } = useContext(AppContext);

    const Btn = () => {
        switch (props?.page) {
            case "landing":
                return (
                    <motion.a
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={{
                            hidden: { opacity: 0, y: "50" },
                            visible: { opacity: 1, y: 0 },
                        }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className="mx-auto mb-5 btn btn-bwa btn-orange"
                        style={{ maxWidth: "300px" }}
                        onClick={() =>
                            setContextData({
                                status: "visible",
                                content: (
                                    <LandingForm image={props?.modalImage} />
                                ),
                            })
                        }
                    >
                        {translations.landing_btn}
                    </motion.a>
                );

            default:
                return (
                    <motion.a
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={{
                            hidden: { opacity: 0, y: "50" },
                            visible: { opacity: 1, y: 0 },
                        }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        href="/"
                        className="mx-auto mb-5 btn btn-bwa"
                        onClick={handleMore}
                    >
                        {translations.showmore}
                    </motion.a>
                );
        }
    };

    const handleMore = (e) => {
        e.preventDefault();
        setCurrentPage(currentPage + 1);
    };

    const fetchData = () => {
        axios
            .get(url + "?page=" + currentPage)
            .then(({ data }) => setData(data))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    return data ? (
        <section className="portfolio" id={data.category_language.slug}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <motion.small
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={{
                                hidden: { opacity: 0, x: "-100" },
                                visible: { opacity: 1, x: 0 },
                            }}
                            transition={{ duration: 1.2, delay: 0 }}
                        >
                            {data.category_language.name}
                        </motion.small>
                        <motion.h1
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={{
                                hidden: { opacity: 0, x: "-100" },
                                visible: { opacity: 1, x: 0 },
                            }}
                            transition={{ duration: 1.2, delay: 1 }}
                        >
                            {data.category_language.description}
                        </motion.h1>
                    </div>
                    <div className="col-xl-6">
                        <div className="tab-headers">
                            <div
                                onClick={() => setPortfolioIndex(0)}
                                className="item"
                            >
                                {translations.all}
                            </div>
                            {data.sub_categories.map((item, index) => {
                                return (
                                    <div
                                        onClick={() =>
                                            setPortfolioIndex(index + 1)
                                        }
                                        className="item"
                                        key={index}
                                    >
                                        {item.category_language.name}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="portfolio-items">
                    <SwipeableViews index={portfolioIndex}>
                        <div className="p-0 m-0 row w-100">
                            {data.all &&
                                data.all.map((item, index) => {
                                    return (
                                        <div
                                            className={`${
                                                index % 6 === 0 ||
                                                (index + 1) % 6 === 0
                                                    ? "col-xl-6"
                                                    : "col-xl-3"
                                            } mb-4`}
                                            key={index}
                                        >
                                            <div className="item">
                                                <Image
                                                    image={item.file}
                                                    alt={
                                                        item?.category_language
                                                            .alt?.file ?? ""
                                                    }
                                                />
                                                <div className="mask">
                                                    <h2>
                                                        {
                                                            item
                                                                .category_language
                                                                .name
                                                        }
                                                    </h2>
                                                    <p>
                                                        {
                                                            item
                                                                .category_language
                                                                .description
                                                        }
                                                    </p>
                                                    <a
                                                        href={
                                                            item
                                                                .category_language
                                                                .taxonomy
                                                        }
                                                        className="more"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faChevronRight
                                                            }
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        {data.sub_categories &&
                            data.sub_categories.map((item, index) => {
                                return (
                                    <div
                                        className="p-0 m-0 row w-100"
                                        key={index}
                                    >
                                        {item.sub_items.map((sitem, sindex) => {
                                            return (
                                                <div
                                                    className={`${
                                                        sindex % 6 === 0 ||
                                                        (sindex + 1) % 6 === 0
                                                            ? "col-xl-6"
                                                            : "col-xl-3"
                                                    } mb-4`}
                                                    key={sindex}
                                                >
                                                    <div className="item">
                                                        <Image
                                                            image={sitem.file}
                                                            alt={
                                                                item
                                                                    .category_language
                                                                    .alt?.file
                                                            }
                                                        />
                                                        <div className="mask">
                                                            <h2>
                                                                {
                                                                    sitem
                                                                        .category_language
                                                                        .name
                                                                }
                                                            </h2>
                                                            <p>
                                                                {
                                                                    sitem
                                                                        .category_language
                                                                        .description
                                                                }
                                                            </p>
                                                            <a
                                                                href={
                                                                    sitem
                                                                        .category_language
                                                                        .taxonomy
                                                                }
                                                                className="more"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faChevronRight
                                                                    }
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                    </SwipeableViews>
                </div>
                <div className="text-center w-100">
                    <Btn />
                </div>
            </div>
        </section>
    ) : (
        <Spinner />
    );
}
