import React,{ useContext } from 'react'
import { motion } from "framer-motion";
import Image from './Image';
import Spinner from './Spinner';
import axios from 'axios';
import useSWR from 'swr';
import AppContext from '../context';
import LandingForm from './LandingForm';

export default function About(props) {
    const compURL = `/api/components/${currentLang}/about`;
    const fetcher = url => axios.get(url).then(res => res.data)
    const { data, error } = useSWR(compURL, fetcher)
    const {setContextData} = useContext(AppContext);

    const Btn = () => {
        switch (props?.page) {
            case 'landing':
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, x:'100'},
                    visible: { opacity: 1, x:0 }
                }}
                transition={{ duration: 1, delay: 1.8 }}
                onClick={() => setContextData({
                    status: 'visible',
                    content: <LandingForm image={props?.modalImage} />
                })}
                style={{ maxWidth:'300px' }}
                className="btn btn-bwa btn-orange btn-orange">
                    {translations.landing_btn}
                </motion.a>

            default:
                return <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once:true }}
                variants={{
                    hidden: { opacity:0, x:'100'},
                    visible: { opacity: 1, x:0 }
                }}
                transition={{ duration: 1, delay: 1.8 }}
                href={data.category_language.taxonomy} className="btn btn-bwa">
                    {translations.readmore}
                </motion.a>
        }
    }
    return (
        data ?
        <section className="container about" id={data.category_language.slug}>
            <div className="px-3 row h-100 px-xl-0">
                <div className="col-xl-6 d-flex justify-content-center align-items-center position-relative">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once:true }}
                        variants={{
                            hidden: { top: 50, right:25},
                            visible: { top: 0, right:0 }
                        }}
                        transition={{ duration: 1.2 }}
                    className="square"></motion.div>
                    <motion.div
                    className="image"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once:true }}
                    variants={{
                        hidden: { marginRight: -50, marginTop:-50},
                        visible: { marginRight: 0, marginTop:0 }
                    }}
                    transition={{ duration: 1.2 }}>
                        <Image image={data.file} alt={data.category_language.alt?.file} />
                    </motion.div>
                </div>
                <div className="col-xl-6 px-xl-4">
                    <small>{data.category_language.name}</small>
                    <motion.h1
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once:true }}
                    variants={{
                        hidden: { opacity:0, x:'100'},
                        visible: { opacity: 1, x:0 }
                    }}
                    transition={{ duration: 1.2 }}>
                        {data.category_language.description}</motion.h1>
                    <motion.h6
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once:true }}
                    variants={{
                        hidden: { opacity:0, x:'100'},
                        visible: { opacity: 1, x:0 }
                    }}
                    transition={{ duration: 1.2, delay: 1 }}
                    dangerouslySetInnerHTML={{ __html: data.category_language.extra.text1 }}></motion.h6>
                    <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once:true }}
                    variants={{
                        hidden: { opacity:0, x:'100'},
                        visible: { opacity: 1, x:0 }
                    }}
                    transition={{ duration: 1.2, delay: 1.5 }}
                    dangerouslySetInnerHTML={{ __html: data.category_language.extra.homeSummary }}></motion.div>
                    <Btn />
                </div>
            </div>
        </section> : <Spinner />
    )
}
