import React, { useState, useContext } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faInstagram,
    faLinkedin,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import AppContext from "./../context";

export default function Footer({ data, inPage, ebulten = true }) {
    const [ebulletinMail, setEbulletinMail] = useState(null);
    const { setContextData } = useContext(AppContext);
    const handleSubmit = (e) => {
        e.preventDefault();
        Axios.post("/misc/ebulletin/register", { email: ebulletinMail })
            .then(() =>
                setContextData({
                    status: "visible",
                    content: translations.form_successful,
                })
            )
            .catch(({ response }) =>
                setContextData({
                    status: "visible",
                    content: response.data.errors.email[0],
                })
            );
    };

    return (
        <div>
            <footer style={{ marginTop: inPage && ebulten && "200px" }}>
                {ebulten && (
                    <div className="container">
                        <form className="e-bulletin" onSubmit={handleSubmit}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <h2>{translations.ebulletin_header}</h2>
                                <h6>{translations.ebulletin_description}</h6>
                            </div>

                            <input
                                type="email"
                                onChange={(e) =>
                                    setEbulletinMail(e.currentTarget.value)
                                }
                                className="form-control"
                                placeholder={translations.email_placeholder}
                            />
                            <button type="submit" className="btn btn-bwa">
                                {translations.send}
                            </button>
                        </form>
                    </div>
                )}

                <div
                    className="container footer-nav"
                    style={!ebulten ? { paddingTop: "100px" } : {}}
                >
                    <div className="row">
                        <div className="col-6 col-xl-3 ">
                            <a href="/" className="footer-logo">
                                <img alt="" src={"/uploads/" + data.logo} />
                            </a>
                            <div className="icon-card">
                                <div className="icon">
                                    <img
                                        alt=""
                                        src="/assets/img/icon-tel.png"
                                        width="37"
                                    />
                                </div>
                                <div className="content">
                                    <a href={"tel:" + data.contact.tel}>
                                        {data.contact.tel}
                                    </a>
                                </div>
                            </div>
                            <div className="icon-card">
                                <div className="icon">
                                    <img
                                        alt=""
                                        src="/assets/img/icon-map.png"
                                        width="37"
                                    />
                                </div>
                                <div className="content">
                                    <p>{data.contact.adres}</p>
                                </div>
                            </div>
                            <div className="icon-card">
                                <div className="icon">
                                    <img
                                        alt=""
                                        src="/assets/img/icon-message.png"
                                        width="37"
                                    />
                                </div>
                                <div className="content">
                                    <a href={"mailto:" + data.contact.email}>
                                        {data.contact.email}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 offset-xl-1 col-6">
                            <h2>{translations.footer_menu_header}</h2>
                            <ul>
                                {data.menu.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {" "}
                                            <a
                                                href={
                                                    item.category_language
                                                        .taxonomy
                                                }
                                            >
                                                {item.category_language.name}
                                            </a>{" "}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="col-xl-3 col-6">
                            <h2>{translations.footer_services_header}</h2>
                            <ul>
                                {data.services.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {" "}
                                            <a
                                                href={
                                                    item.category_language
                                                        .taxonomy
                                                }
                                            >
                                                {item.category_language.name}
                                            </a>{" "}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="col-xl-3 col-6">
                            <h2>{translations.footer_products_header}</h2>
                            <ul>
                                {data.products.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {" "}
                                            <a
                                                target="_blank"
                                                href={
                                                    item.category_language
                                                        .taxonomy
                                                }
                                            >
                                                {item.category_language.name}
                                            </a>{" "}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="socials">
                                {data.social_media.facebook && (
                                    <a
                                        href={data.social_media.facebook}
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                )}
                                {data.social_media.instagram && (
                                    <a
                                        href={data.social_media.instagram}
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                )}
                                {data.social_media.twitter && (
                                    <a
                                        href={data.social_media.twitter}
                                        target="_blank"
                                    >
                                        <svg
                                            width={13}
                                            fill="#0970c6"
                                            id="Layer_2"
                                            data-name="Layer 2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24.91 24"
                                        >
                                            <g
                                                id="Layer_1-2"
                                                data-name="Layer 1"
                                            >
                                                <path d="m14.79,9.61L23,0h-2.5l-6.84,8.01L8.02,0H.09l9.22,13.1L0,24h2.5l7.94-9.29,6.54,9.29h7.94l-10.12-14.39ZM3.91,2h3.06l14.11,20h-3.06L3.91,2Z" />
                                            </g>
                                        </svg>
                                    </a>
                                )}
                                {data.social_media.youtube && (
                                    <a
                                        href={data.social_media.youtube}
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </a>
                                )}
                                {data.social_media.linkedin && (
                                    <a
                                        href={data.social_media.linkedin}
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <p
                            dangerouslySetInnerHTML={{ __html: data.copyright }}
                        ></p>
                        <div>
                            {data.footer_links.map((item, index) => {
                                return (
                                    <a
                                        href={item.category_language.taxonomy}
                                        key={index}
                                    >
                                        {item.category_language.name}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
