import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import useSWR from "swr";
import Spinner from "./components/Spinner";
import FloatingItems from "./extras/floatingItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./../sass/style.scss";
import Modal from "./components/Modal";
import Feedback from "./components/Feedback";
import References from "./components/References";
import ContactInfo from "./components/ContactInfo";
import ContactForm from "./components/ContactForm";
import AppContext from "./context";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Products from "./components/Products";
import Blog from "./components/Blog";
import About from "./components/About";
import Awards from "./components/Awards";

const url = window.location.pathname;
const fetcher = (url) => axios.get(url).then((res) => res.data);
function App() {
    const [contextData, setContextData] = useState(null);
    const { data, error } = useSWR("/api" + url, fetcher);


    useEffect(() => {
        if (data) {
            FloatingItems();
        }
    }, [data]);

    return data ? (
        <AppContext.Provider value={{ contextData, setContextData }}>
            <Header data={data.header} />
            <section className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <motion.h1
                                initial={{ opacity: 0, x: "-100" }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1.2 }}
                            >
                                {data.banner.category_language.description}
                            </motion.h1>
                            <motion.div
                                initial={{ opacity: 0, x: "-100" }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1.2, delay: 1 }}
                                dangerouslySetInnerHTML={{
                                    __html: data.banner.category_language
                                        .contents,
                                }}
                            ></motion.div>
                            <motion.div
                                initial={{ opacity: 0, x: "-100" }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1.2, delay: 2.2 }}
                                className="analysis"
                            >
                                <motion.a
                                    className="btn d-flex align-items-center gap-2"
                                    initial={{ opacity: 0, x: "-100" }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.9, delay: 3.2 }}
                                    href={
                                        data.online.category_language.taxonomy
                                    }
                                >
                                    <svg
                                        id="Layer_2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 52.79 49.02"
                                        width={"1.5em"}
                                        height={"1.5em"}
                                    >
                                        <g id="Layer_1-2" data-name="Layer_1">
                                            <g>
                                                <path
                                                    d="M46.5,27V9.7c0-2.5-2-4.5-4.5-4.5H5.5c-2.5,0-4.5,2-4.5,4.5v29.7c0,2.5,2,4.5,4.5,4.5h26.1"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <line
                                                    x1="1"
                                                    y1="12.9"
                                                    x2="46.5"
                                                    y2="12.9"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <path
                                                    d="M12.3,9.2c-2,0-3.6-1.8-3.6-4.1s1.6-4.1,3.6-4.1,3.6,1.8,3.6,4.1"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <path
                                                    d="M23.8,9.2c-2,0-3.6-1.8-3.6-4.1s1.6-4.1,3.6-4.1,3.6,1.8,3.6,4.1"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <path
                                                    d="M35.2,9.2c-2,0-3.6-1.8-3.6-4.1s1.6-4.1,3.6-4.1,3.6,1.8,3.6,4.1"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <rect
                                                    x="8"
                                                    y="18.7"
                                                    width="7.3"
                                                    height="7.3"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <rect
                                                    x="20.4"
                                                    y="18.7"
                                                    width="7.3"
                                                    height="7.3"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <rect
                                                    x="8"
                                                    y="30.5"
                                                    width="7.3"
                                                    height="7.3"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <rect
                                                    x="20.4"
                                                    y="30.5"
                                                    width="7.3"
                                                    height="7.3"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <rect
                                                    x="32.8"
                                                    y="18.7"
                                                    width="7.3"
                                                    height="7.3"
                                                    style={{
                                                        fill: "none",
                                                        stroke: "#fff",
                                                        strokeLinecap: "round",
                                                        strokeLinejoin: "round",
                                                        strokeWidth: "2px",
                                                    }}
                                                />
                                                <g>
                                                    <path
                                                        d="M29.97,36.3c0,.26-.05.52-.05.78,0,6.12,4.96,11.03,11.01,11.03s11.06-4.91,11.06-11.03-4.96-11.03-11.01-11.03"
                                                        style={{
                                                            fill: "none",
                                                            stroke: "#fff",
                                                            strokeLinecap:
                                                                "round",
                                                            strokeLinejoin:
                                                                "round",
                                                            strokeWidth:
                                                                "1.6px",
                                                        }}
                                                    />
                                                    <path
                                                        d="M40.93,26.06c1.88,0,3.44,2.88,4.12,6.95.21,1.25.31,2.67.31,4.13s-.1,2.82-.31,4.13c-.63,4.08-2.24,6.95-4.12,6.95s-3.44-2.88-4.12-6.95c-.21-1.25-.31-2.67-.31-4.13v-.78"
                                                        style={{
                                                            fill: "none",
                                                            stroke: "#fff",
                                                            strokeLinecap:
                                                                "round",
                                                            strokeLinejoin:
                                                                "round",
                                                            strokeWidth:
                                                                "1.6px",
                                                        }}
                                                    />
                                                    <path
                                                        d="M40.93,32.7c1.46,0,2.82.1,4.12.31,4.07.63,6.94,2.25,6.94,4.08s-2.87,3.45-6.94,4.13c-1.25.21-2.66.31-4.12.31s-2.82-.1-4.12-.31c-4.07-.63-6.94-2.25-6.94-4.13,0-.26.05-.52.21-.78"
                                                        style={{
                                                            fill: "none",
                                                            stroke: "#fff",
                                                            strokeLinecap:
                                                                "round",
                                                            strokeLinejoin:
                                                                "round",
                                                            strokeWidth:
                                                                "1.6px",
                                                        }}
                                                    />
                                                    <path
                                                        d="M40.14,48.14c.26,0,.52.05.78.05,6.11,0,11.01-4.97,11.01-11.03s-4.91-11.08-11.01-11.08-11.01,4.97-11.01,11.03"
                                                        style={{
                                                            fill: "none",
                                                            stroke: "#fff",
                                                            strokeLinecap:
                                                                "round",
                                                            strokeLinejoin:
                                                                "round",
                                                            strokeWidth:
                                                                "1.6px",
                                                        }}
                                                    />
                                                    <path
                                                        d="M40.93,48.22c-1.88,0-3.44-2.88-4.12-6.95-.21-1.25-.31-2.67-.31-4.13s.1-2.82.31-4.13c.63-4.08,2.24-6.95,4.12-6.95s3.44,2.88,4.12,6.95c.21,1.25.31,2.67.31,4.13v.78"
                                                        style={{
                                                            fill: "none",
                                                            stroke: "#fff",
                                                            strokeLinecap:
                                                                "round",
                                                            strokeLinejoin:
                                                                "round",
                                                            strokeWidth:
                                                                "1.6px",
                                                        }}
                                                    />
                                                    <path
                                                        d="M40.93,41.56c-1.46,0-2.82-.1-4.12-.31-4.07-.63-6.94-2.25-6.94-4.08s2.87-3.45,6.94-4.13c1.25-.21,2.66-.31,4.12-.31s2.82.1,4.12.31c4.07.63,6.94,2.25,6.94,4.13,0,.26-.05.52-.21.78"
                                                        style={{
                                                            fill: "none",
                                                            stroke: "#fff",
                                                            strokeLinecap:
                                                                "round",
                                                            strokeLinejoin:
                                                                "round",
                                                            strokeWidth:
                                                                "1.6px",
                                                        }}
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>
                                        {data.online.category_language.name}
                                    </span>
                                </motion.a>

                                <motion.a
                                    className="btn d-flex align-items-center gap-2"
                                    initial={{ opacity: 0, x: "-100" }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.9, delay: 3.2 }}
                                    href={
                                        "#" +
                                        data.contact.category_language.slug
                                    }
                                >
                                    <svg
                                        id="Outline"
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        viewBox="0 0 48 48"
                                        width={"1.8em"}
                                        height={"1.8em"}
                                    >
                                        <path
                                            d="M40,5H12c-1.6543,0-3,1.3457-3,3v19h-1c-1.6543,0-3,1.3457-3,3v6c0,1.6543,1.3457,3,3,3h1.21875l.81152,3.24268c.09277.37402.39355.66113.77154.73732.37381.07694.76916-.07479.99799-.37989l2.7002-3.6001h9.5c1.6543,0,3-1.3457,3-3v-5h13c1.6543,0,3-1.3457,3-3V8c0-1.6543-1.3457-3-3-3ZM24,27h-9.58594l7.58594-7.58594,3.29297,3.29297c.19531.19531.45117.29297.70703.29297s.51172-.09766.70703-.29297l3.29297-3.29297,9.58594,9.58594h-12.7702c-.41412-1.1615-1.51379-2-2.81573-2ZM26,20.58594L12.41406,7h27.17188l-13.58594,13.58594ZM31.41406,18l9.58594-9.58594v19.17188l-9.58594-9.58594ZM11,8.41406l9.58594,9.58594-9,9h-.58594V8.41406ZM25,36c0,.55127-.44824,1-1,1h-10c-.31445,0-.61133.14795-.7998.3999l-1.73926,2.31934-.49121-1.96191c-.11133-.44482-.51074-.75732-.96973-.75732h-2c-.55176,0-1-.44873-1-1v-6c0-.55127.44824-1,1-1h16c.55176,0,1,.44873,1,1v6Z"
                                            style={{
                                                fill: "#fff",
                                                strokeWidth: 0,
                                            }}
                                        />
                                        <circle
                                            cx="10"
                                            cy="33"
                                            r="1"
                                            style={{
                                                fill: "#fff",
                                                strokeWidth: 0,
                                            }}
                                        />
                                        <circle
                                            cx="14"
                                            cy="33"
                                            r="1"
                                            style={{
                                                fill: "#fff",
                                                strokeWidth: 0,
                                            }}
                                        />
                                        <circle
                                            cx="18"
                                            cy="33"
                                            r="1"
                                            style={{
                                                fill: "#fff",
                                                strokeWidth: 0,
                                            }}
                                        />
                                        <circle
                                            cx="22"
                                            cy="33"
                                            r="1"
                                            style={{
                                                fill: "#fff",
                                                strokeWidth: 0,
                                            }}
                                        />
                                    </svg>
                                    <span>
                                        {translations.iletisim_formunu_doldur}
                                    </span>
                                </motion.a>

                                {/* <input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                        translations.intro_input_placeholder
                                    }
                                />
                                <button
                                    className="btn"
                                    onClick={() =>
                                        setContextData({
                                            status: "visible",
                                            content:
                                                "Bu bölümün yapımı en kısa sürede tamamlanacaktır. \nThe construction of this section will be completed as soon as possible.",
                                        })
                                    }
                                >
                                    {translations.intro_form_button}
                                </button> */}
                            </motion.div>
                            <div className="contact-info">
                                <motion.a
                                    initial={{ opacity: 0, x: "-100" }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.9, delay: 3.2 }}
                                    href="/"
                                >
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                    {data.banner.category_language.extra.phone}
                                </motion.a>
                                <motion.a
                                    initial={{ opacity: 0, x: "-100" }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.9, delay: 4 }}
                                    href="/"
                                >
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    {data.banner.category_language.extra.email}
                                </motion.a>
                            </div>
                        </div>
                        <motion.div
                            animate={{
                                opacity: [0, 1],
                                borderRadius: ["0%", "100%"],
                            }}
                            transition={{ duration: 1.5, delay: 1.5 }}
                            className="col-xl-6 floating-items-container"
                            style={{
                                backgroundImage: `url('${data.banner.file}')`,
                            }}
                        >
                            <div className="floating-items">
                                <div className="element-one">
                                    <img
                                        alt=""
                                        src={
                                            data.banner.category_language.extra
                                                .icon1
                                        }
                                        className="small-img"
                                    />
                                </div>

                                <div className="element-two">
                                    <img
                                        alt=""
                                        src={
                                            data.banner.category_language.extra
                                                .icon2
                                        }
                                        className="small-img"
                                    />
                                </div>

                                <div className="element-three">
                                    <img
                                        alt=""
                                        src={
                                            data.banner.category_language.extra
                                                .icon3
                                        }
                                        className="small-img"
                                    />
                                </div>

                                <div className="element-four">
                                    <img
                                        alt=""
                                        src={
                                            data.banner.category_language.extra
                                                .icon4
                                        }
                                        className="small-img"
                                    />
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <div className="bubbles_wrap">
                <div className="bubble x1"></div>
                <div className="bubble x2"></div>
                <div className="bubble x3"></div>
                <div className="bubble x4"></div>
                <div className="bubble x5"></div>
                <div className="bubble x6"></div>
                <div className="bubble x7"></div>
                <div className="bubble x8"></div>
                <div className="bubble x9"></div>
                <div className="bubble x10"></div>
            </div>

            <About />

            <Services />

            <Products />

            <Feedback />

            <div className="fluid-bg">
                <Portfolio />
                <Blog />
                <Awards />
                <References />
            </div>

            <section
                className="contact"
                id={data.contact.category_language.slug}
            >
                <small>{data.contact.category_language.extra.small}</small>
                <h1>{data.contact.category_language.description}</h1>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-xxl-4 col-lg-5 d-flex flex-column align-items-center justify-content-between">
                            <ContactInfo data={data.contact} />
                        </div>
                        <div className="col-xxl-8 col-lg-7 ps-xxl-5">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
            <Modal />
            <div
                className="map"
                dangerouslySetInnerHTML={{
                    __html: data.contact.category_language.extra.map,
                }}
            ></div>
            <Footer data={data.footer} />
        </AppContext.Provider>
    ) : (
        <Spinner />
    );
}

ReactDOM.render(<App />, document.getElementById("root"));
