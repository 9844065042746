import React, { useEffect } from "react";
import Swiper, { Autoplay } from "swiper";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";
import useSWR from "swr";
import Spinner from "./Spinner";

export default function References({ appendClass }) {
    const compURL = `/api/components/${currentLang}/references`;
    const fetcher = (url) => axios.get(url).then((res) => res.data);
    const { data, error } = useSWR(compURL, fetcher);
    useEffect(() => {
        //references
        Swiper.use([Autoplay]);
        new Swiper(".ref-slider", {
            loop: true,
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1366: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                },
            },
        });
    });
    return data ? (
        <section className={`references ${appendClass}`}>
            <motion.small
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={{
                    hidden: { opacity: 0, y: "50" },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.4, delay: 0 }}
            >
                {data?.category_language.description}
            </motion.small>
            <motion.h1
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={{
                    hidden: { opacity: 0, y: "50" },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.4, delay: 0.4 }}
            >
                {data?.category_language.name}
            </motion.h1>
            <div className="border-top border-bottom w-100">
                <div className="container">
                    <div className="swiper ref-slider">
                        <div className="swiper-wrapper">
                            {data?.sub_items.map((item, index) => {
                                return (
                                    <div className="swiper-slide" key={index}>
                                        <div className="item">
                                            <img
                                                alt={
                                                    item.category_language.alt
                                                        ?.file
                                                }
                                                src={item.file}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <Spinner />
    );
}
