export default function FloatingItems(){
    //floating items
let oldx = 0;
let oldy = 0;
//let direction = '';
let tx = 0;
let ty = 0;
const introEl = document.querySelector(".intro");

introEl.addEventListener('mousemove',function (e){
    let sliderWidth = e.currentTarget.offsetWidth;
    let sliderHeight = e.currentTarget.offsetHeight;

    var relativeXPosition = (e.pageX - this.offsetLeft); //offset -> method allows you to retrieve the current position of an element 'relative' to the document
    var relativeYPosition = (e.pageY - this.offsetTop);
    
    if (relativeXPosition < oldx && relativeYPosition < oldy) {
        //direction = 'left-up';
        tx = (sliderWidth - relativeXPosition) / 100;
        ty = (sliderHeight - relativeYPosition) / 90;
    } else if (relativeXPosition < oldx && relativeYPosition > oldy) {
        //direction = 'left-down';
        tx = (sliderWidth - relativeXPosition) / 100;
        ty = (sliderHeight - relativeYPosition) / 90;
    } else if (relativeXPosition > oldx && relativeYPosition > oldy) {
        //direction = 'right-down';
        tx = (sliderWidth - relativeXPosition) / 100;
        ty = (sliderHeight - relativeYPosition) / 90;
    } else if (relativeXPosition > oldx && relativeYPosition < oldy) {
        //direction = 'right-up';
        tx = (sliderWidth - relativeXPosition) / 100;
        ty = (sliderHeight - relativeYPosition) / 90;
    } else if (relativeXPosition < oldx && relativeYPosition === oldy) {
        //direction = 'left';
        tx = (sliderWidth - relativeXPosition) / 100;

    } else if (relativeXPosition > oldx && relativeYPosition === oldy) {
        //direction = 'right';
        tx = (sliderWidth - relativeXPosition) / 100;

    } else if (relativeXPosition === oldx && relativeYPosition < oldy) {
        //direction = 'up';
        ty = (sliderHeight - relativeYPosition) / 90

    } else if (relativeXPosition === oldx && relativeYPosition > oldy) {
        //direction = 'down';
        ty = (sliderHeight - relativeYPosition) / 90
    }
    oldx = relativeXPosition;
    oldy = relativeYPosition;

    document.querySelectorAll('.floating-items > div').forEach((e) => e.style.transform = 'translate3d(' + tx + 'px,' + ty + 'px ,0px)')
    
});

}