import { useState } from 'react'

export default function useScreenSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    window.onresize = () => setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
    })

    return windowSize;
}
