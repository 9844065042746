import React from 'react'

export default function Spinner() {
    const SpinnerContainer = {
        width:'100%',
        height:'100vh',
        display:'flex',
        justifyContent:'center',
        alignItems: 'center'
    }
    return (
    <div style={SpinnerContainer}>
    <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
    </div>
    )
}
