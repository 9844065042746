import React, {useContext} from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import AppContext from '../context'

export default function Modal() {
    const {contextData, setContextData} = useContext(AppContext);
    return (
    contextData?.status === 'visible' && (
        <motion.div
    initial="hidden"
    tabIndex={0}
    onClick={e => e.target.classList.contains('bwa-modal') && setContextData({status: 'hidden'})}
    animate={contextData?.status}
    variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1}
    }}
    transition={{ duration:.7 }}
    className="bwa-modal">
        <motion.div
        initial="hidden"
        animate={contextData?.status}
        variants={{
            hidden: {
                opacity: 0,
                borderRadius: 0,
                //scale: 0,
                top:'-200px'
            },
            visible: {
                opacity: 1,
                borderRadius: '8px',
                //scale: [1.1, 1, 1.05, 1, 1.02, 1],
                position:'relative',
                top:0,
                bottom:0
            }
        }}
        transition={{ duration:1, delay:.7 }}
        className="bm-body">
            <div
            className="close-modal"
            onClick={() => setContextData({status:'hidden'})}>
            <div>
                <FontAwesomeIcon icon={faTimes} />
            </div>
            </div>
            {contextData?.video ? (
                <iframe
                width="560"
                height="315"
                src={"https://www.youtube.com/embed/"+contextData?.video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            ) : contextData?.content}
        </motion.div>
    </motion.div>
    )
  )
}
