import React,{ useState, useEffect } from 'react'
import Axios from 'axios'

export default function Image({image,alt}) {
    const [img, setImg] = useState(null);

    useEffect(() => {
        Axios.patch('/api/image',{image})
        .then(({data}) => setImg(data))
        .catch(err => console.log(err));
    }, [])
    return (
        image ? (
            img ? (
                <picture>
                {img.srcset && img.srcset.length > 0 && (
                    img.srcset.map((item,index) => {
                        return <source key={index} media={`(max-width:${item.size}px)`} srcSet={item.url}></source>
                    })
                )}
                <img src={image} loading="lazy" alt={alt}></img>
                </picture>
            ) : <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : <>...</>
    )
}
